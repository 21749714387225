.react-resizable-handle {
  $padding-gap: 5px;
  $hit-area-size: 15px;
  $handle-size: 7px;

  padding: 0 $padding-gap $padding-gap 0;

  width: $hit-area-size !important;
  height: $hit-area-size !important;

  &::after {
    right: $padding-gap !important;
    bottom: $padding-gap !important;

    width: $handle-size !important;
    height: $handle-size !important;
  }
}