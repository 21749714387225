// Styles for the react-menu

.CategoryDropdownMenu__menu, .CategoryDropdownMenu__sub-menu {
  background: var(--chakra-colors-dashboard-selection-dropdown-primary);

  color: var(--chakra-colors-white);

  font-size: var(--chakra-fontSizes-md);
  font-weight: var(--chakra-fontWeights-normal);

  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
}

.CategoryDropdownMenu__menu {
  border-radius: 0 0 0.25rem 0.25rem;
}

.CategoryDropdownMenu__sub-menu {
  border-radius: 0.25rem;
}

.CategoryDropdownMenu__sub-menu-item, .CategoryDropdownMenu__menu-item {
  background: var(--chakra-colors-dashboard-selection-dropdown-primary);

  &--active {
    background: var(--chakra-colors-dashboard-selection-dropdown-secondary);
  }

  &--hover {
    background: var(--chakra-colors-dashboard-selection-dropdown-secondary);
  }

  &--uncategorized {
    color: var(--chakra-colors-gray-100);
    font-style: italic;
  }

  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
}

.CategoryDropdownMenu__sub-menu-item {
  &::after {
    transform: scale(0.7);
  }
}