::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: var(--chakra-colors-page-scrollbar-track);
}

::-webkit-scrollbar-thumb {
  background: var(--chakra-colors-page-scrollbar-thumb);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--chakra-colors-page-scrollbar-thumb-hover);
}

.inline-scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  &::-webkit-scrollbar-track {
    background: var(--chakra-colors-inline-scrollbar-track);
  }
  
  &::-webkit-scrollbar-thumb {
    background: var(--chakra-colors-inline-scrollbar-thumb);
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: var(--chakra-colors-inline-scrollbar-thumb-hover);
  }
}